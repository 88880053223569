<template>
  <div>
    <div class="overlay" v-if="iloading.isloading">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
import { loading } from "@/store/loading";
export default {
  name: "LoadingComm",
  setup() {
    const iloading = loading();
    return {
      iloading,
    };
  },
};
</script>
