import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import 'bootstrap/dist/css/bootstrap.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueHtmlToPaper from 'vue-html-to-paper';
import { createPinia } from 'pinia'
var cros = require('cors');

createApp(App).use(createPinia()).use(cros).use(VueHtmlToPaper).use(VueSweetalert2).use(router).mount('#app')
