<template>
  <!-- Just an image -->
  <LoadingComm></LoadingComm>
  <div class="container">
    <nav class="navbar navbar-light" style="background: none">
      <a class="navbar-brand" href="https://fezzantechx.ly">
        <img src="/assets/Asset-18-1024x449.png" width="120" height="50" alt="" />
      </a>
      <a class="navbar-brand" href="https://fezzantechx.ly">
        <img src="/assets/Asset-19-1024x576.png" width="120" height="60" alt="" />
      </a>
    </nav>
    <router-view />
  </div>
</template>
<script>
import LoadingComm from "@/components/Loading.vue";
import { loading } from "./store/loading";
export default {
  components: {
    LoadingComm,
  },
  setup() {
    const iloading = loading();
  },
};
</script>
