import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  
  {
    path: '/new-comp-form',
    name: 'NewComp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/RegisterCompView.vue')
    }
  },
  {
    path: '/new-project-form',
    name: 'NewProject',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/RegisterProjectView.vue')
    }
    
  },
  {
    path: '/new-guests-form',
    name: 'NewGuests',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/RegisterGustisView.vue')
    }
    
  },
  {
    path: '/new-ballot/:id',
    name: 'ballot',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/RegisterCloudsView.vue')
    }
    
  },
  {
    path: '/new-player-form',
    name: 'player',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/RegisterPlayerView.vue')
    }
    
  },
  { path: '/:catchAll(.*)', component: () => import('@/views/404View.vue') }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
