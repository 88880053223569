import {defineStore} from 'pinia';


export const loading = defineStore('loading',{
    state:()=>({
        isloading:false
    }),
    actions: {
        startLoading(){
            this.isloading = true
        },
        endLoading(){
            this.isloading = false
        }
    },
})